import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useInView } from "react-intersection-observer"
import ProductContainer from "../components/productContainer"
import descriptionData from "../content/description.json"
import buildSchema from "../script/buildschema"

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const TrendingAll = ({
  data: {
    allAirtable: { edges },
  },
}) => {
  // const data = props.data.allAirtable.edges
  const {
    ref,
    inView: fixHeader,
    entry,
  } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  buildSchema(edges)
  return (
    <Layout>
      <Seo
        title={descriptionData.archive_all.pageTitle}
        description={descriptionData.archive_all.pageDescription}
      />
      <div className="descriptions-container container px-12 mx-auto max-w-screen-xl">
        <h1 className="text-2xl mt-5 mb-5 font-bold">
          {descriptionData.archive_all.heading}
        </h1>
        <p className="hidden md:block">
          We aggregate the newest and hottest{" "}
          <Link to={"/maxi-dresses"}>dresses</Link> from different sources, so
          you don't need to spend countless hours browsing different stores and
          boutiques to discover it. Whether you want to wear a{" "}
          <Link to={"/maxi-dresses"}>maxi dress</Link> for a party or a night
          out with the girls, you'll be right on trend in the latest fashion
          wear. You'll find the biggest and best selection of women's dresses at
          Collective dress. Every week, we add and refresh new styles in our
          dress collection, where you'll find{" "}
          <Link to={"/mini-dresses"}>mini dresses</Link>,{" "}
          <Link to={"/midi-dresses"}>midi dresses</Link>, and{" "}
          <Link to={"/party-dresses"}>party dresses</Link>. Also check out our{" "}
          <Link to={"/maxi-dresses"}>maxi dresses</Link> too. We have maxi
          dresses in all styles, like a scoop neck, V-neck, or off shoulder.
        </p>

        <p className="text-l mt-5 mb-5 font-bold">
          Website data refreshes every Monday, Wednesday, Friday
        </p>
      </div>

      <div className="product-container grid grid-cols-2 gap-2 md:grid-cols-3 max-w-screen-xl mx-auto">
        <ProductContainer edges={edges} dressType="TNew" />
      </div>
      <p className="lg:hidden">
        We aggregate the newest and hottest{" "}
        <Link to={"/maxi-dresses"}>dresses</Link> from different sources, so you
        don't need to spend countless hours browsing different stores and
        boutiques to discover it. Whether you want to wear a{" "}
        <Link to={"/maxi-dresses"}>maxi dress</Link> for a party or a night out
        with the girls, you'll be right on trend in the latest fashion wear.
        You'll find the biggest and best selection of women's dresses at
        Collective dress. Every week, we add and refresh new styles in our dress
        collection, where you'll find{" "}
        <Link to={"/mini-dresses"}>mini dresses</Link>,{" "}
        <Link to={"/midi-dresses"}>midi dresses</Link>, and{" "}
        <Link to={"/party-dresses"}>party dresses</Link>. Also check out our{" "}
        <Link to={"/maxi-dresses"}>maxi dresses</Link> too. We have maxi dresses
        in all styles, like a scoop neck, V-neck, or off shoulder.
      </p>
    </Layout>
  )
}

export const query = graphql`
  query {
    allAirtable(filter: { table: { eq: "archive_dresses_all" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
  }
`

export const Head = () => (
  <Seo
    title={descriptionData.archive_all.pageTitle}
    description={descriptionData.archive_all.pageDescription}
  />
)

export default TrendingAll
